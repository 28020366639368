import ingenieria from '../assets/navbar-imgs/ingenieria.png'
import fatSat from '../assets/navbar-imgs/fatsat.jpg'
import utrs from '../assets/navbar-imgs/utrs.jpg'
import supervision from '../assets/navbar-imgs/supervision.jpg'
import eep from '../assets/navbar-imgs/eep.JPG'
import cursos from '../assets/navbar-imgs/cursos.jpg'
import endToEnd from '../assets/navbar-imgs/end-to-end.jpg'
import soporte from '../assets/navbar-imgs/soporte.jpg'

import merging from '../assets/navbar-imgs/merging-unit.jpg'
import goose from '../assets/navbar-imgs/goose.jpg'
import bus from '../assets/navbar-imgs/bus.png'
import pmu from '../assets/navbar-imgs/pmu.png'
import rd from '../assets/navbar-imgs/rd.jpg'
import firewall from '../assets/navbar-imgs/firewall.jpg'
import switchDevice from '../assets/navbar-imgs/switch.jpg'
import ace from '../assets/navbar-imgs/ace.jpg'
import dnp from '../assets/navbar-imgs/dnp.PNG'
import especiales from '../assets/navbar-imgs/especiales.jpg'
import dag from '../assets/navbar-imgs/dag.jpg'
import dac from '../assets/navbar-imgs/dac.jpg'
import ear from '../assets/navbar-imgs/ear.PNG'
import drb from '../assets/navbar-imgs/drb.PNG'
import efs from '../assets/navbar-imgs/efs.JPG'

import cmc356 from '../assets/navbar-imgs/cmc356.png'
import cmc353 from '../assets/navbar-imgs/cmc353.png'
import cmgps588 from '../assets/navbar-imgs/cmgps588.png'
import megger from '../assets/navbar-imgs/megger.jpg'
import fluke from '../assets/navbar-imgs/fluke199c.jpg'
import tektronix from '../assets/navbar-imgs/tektronix.jpg'

export const navigation = {
  categories: [
    {
      name: 'Servicios',
      featured: [
        {
          name: 'Ingeniería',
          href: '/',
          imageSrc: `${ingenieria}`,
          imageAlt: 'Diseño de ingenieria y arquitectura de comunicaciones.',
        },
        {
          name: 'FAT & SAT',
          href: '/',
          imageSrc: `${fatSat}`,
          imageAlt: 'Factory Acceptance Test and Site Acceptance Test',
        },
        {
          name: 'UTRs, SCADAS, IHMs',
          href: '/',
          imageSrc: `${utrs}`,
          imageAlt: 'Unit Terminal Remote',
        },
        {
          name: 'Mantenimientos / Supervisión',
          href: '/',
          imageSrc: `${supervision}`,
          imageAlt: 'Supervision y mantenimientos.',
        },
        {
          name: 'Pruebas a EEP',
          href: '/',
          imageSrc: `${eep}`,
          imageAlt: 'Pruebas a equipo electrico primario.',
        },
        {
          name: 'Cursos',
          href: '/',
          imageSrc: `${cursos}`,
          imageAlt: 'Cursos de PCyM.',
        },
        {
          name: 'Pruebas end-to-end',
          href: '/',
          imageSrc: `${endToEnd}`,
          imageAlt: 'Pruebas end-to-end en colaterales.',
        },
        {
          name: 'Soporte Remoto/Sitio',
          href: '/',
          imageSrc: `${soporte}`,
          imageAlt: 'Soporte remoto o en sitio.',
        },
      ],
    },
    {
      name: 'Soluciones',
      featured: [
        {
          name: 'Merging Unit/Sampled Measured Values',
          href: '/',
          imageSrc: `${merging}`,
          imageAlt: 'Mergin Units and sampled measured values.',
        },
        {
          name: 'Mensajes GOOSE/MMS',
          href: '/',
          imageSrc: `${goose}`,
          imageAlt: 'Diagrama de mensajes GOOSE y MMS.',
        },
        {
          name: 'Bus de Procesos y VLANs',
          href: '/',
          imageSrc: `${bus}`,
          imageAlt: 'Bus de procesos aplicado a subestaciones.',
        },
        {
          name: 'Phasor Measurement Unit',
          href: '/',
          imageSrc: `${pmu}`,
          imageAlt: 'Phasor measurement unit.',
        },
        {
          name: 'Registrador de Disturbios',
          href: '/',
          imageSrc: `${rd}`,
          imageAlt: 'Registrador de disturbios.',
        },
        {
          name: 'Firewalls',
          href: '/',
          imageSrc: `${firewall}`,
          imageAlt: 'Firewall device.',
        },
        {
          name: 'Switch Capa 2/3',
          href: '/',
          imageSrc: `${switchDevice}`,
          imageAlt: 'Switch capa 2 y 3.',
        },
        {
          name: 'Analizador de Calidad de la Energía',
          href: '/',
          imageSrc: `${ace}`,
          imageAlt: 'Analizador de calidad de la energia.',
        },
        {
          name: 'Pruebas de Perfíl de Protocolo (CENACE)',
          href: '/',
          imageSrc: `${dnp}`,
          imageAlt: 'Pruebas de perfil de protocolo DNP 3.',
        },
        {
          name: 'Configuración de Equipos Especiales',
          href: '/',
          imageSrc: `${especiales}`,
          imageAlt: 'Configuración de equipos especiales.',
        },
      ],
    },
    {
      name: 'Automatismos',
      featured: [
        {
          name: 'Disparo automático de Generación',
          href: '/',
          imageSrc: `${dag}`,
          imageAlt: 'Disparo automatico de generacion.',
        },
        {
          name: 'Disparo automático de Carga',
          href: '/',
          imageSrc: `${dac}`,
          imageAlt: 'Disparo automático de carga.',
        },
        {
          name: 'Esquema de Acción Remedial',
          href: '/',
          imageSrc: `${ear}`,
          imageAlt: 'Esquema de accion remedial.',
        },
        {
          name: 'Disparo Rapido de Bus',
          href: '/',
          imageSrc: `${drb}`,
          imageAlt: 'Disparo rapido de bus.',
        },
        {
          name: 'Esquema de Fallas Simultáneas',
          href: '/',
          imageSrc: `${efs}`,
          imageAlt: 'Esquema de fallas simultaneas.',
        },
      ],
    },
    {
      name: 'Arrendamiento',
      featured: [
        {
          name: 'Omicron CMC356',
          href: '/',
          imageSrc: `${cmc356}`,
          imageAlt: 'Equipo cmc 356 omicron.',
        },
        {
          name: 'Omicron CMC353',
          href: '/',
          imageSrc: `${cmc353}`,
          imageAlt: 'Equipo omicron cmc 353.',
        },
        {
          name: 'Omicron CMGPS 588',
          href: '/',
          imageSrc: `${cmgps588}`,
          imageAlt: 'Omicron cmgps588',
        },
        {
          name: 'Equipos Megger',
          href: '/',
          imageSrc: `${megger}`,
          imageAlt: 'Equipos megger',
        },
        {
          name: 'Equipos Fluke',
          href: '/',
          imageSrc: `${fluke}`,
          imageAlt: 'Equipos fluke',
        },
        {
          name: 'Tektronix',
          href: '/',
          imageSrc: `${tektronix}`,
          imageAlt: 'Equipo tektronix.',
        },
      ],
    },
  ],
  pages: [
    { name: 'Tienda', href: '/' },
    { name: 'Nosotros', href: '/nosotros' },
  ],
} 