export const footerNavigation = {
  servicios: [
    { name: "Ingeniería", href: "#" },
    { name: "Fat & SAT", href: "#" },
    { name: "UTRs, SCADAS, IHMs", href: "#" },
    { name: "Mantenimientos / Supervisión", href: "#" },
    { name: "Pruebas a EEP", href: "#" },
    { name: "Cursos", href: "#" },
    { name: "Pruebas end-to-end", href: "#" },
    { name: "Soporte Remoto/Sitio", href: "#" },
  ],
  soluciones: [
    { name: "Merging Unit/Sampled Measured Values", href: "#" },
    { name: "Mensajes GOOSE/MMS", href: "#" },
    { name: "Bus de Procesos y VLANs", href: "#" },
    { name: "Phasor Measurement Unit", href: "#" },
    { name: "Registrador de Disturbios", href: "#" },
    { name: "Firewalls", href: "#" },
    { name: "Switch Capa 2/3", href: "#" },
    { name: "Analizador de Calidad de la Energía", href: "#" },
    { name: "Pruebas de Perfíl de Protocolo (CENACE)", href: "#" },
    { name: "Configuración de Equipos Especiales", href: "#" },
  ],
  automatismos: [
    { name: "Disparo automático de Generación", href: "#" },
    { name: "Disparo automático de Carga", href: "#" },
    { name: "Esquema de Acción Remedial", href: "#" },
    { name: "Disparo Rapido de Bus", href: "#" },
    { name: "Esquema de Fallas Simultáneas", href: "#" },
  ],
};
