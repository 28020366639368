import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Global/Header";
import Home from "./Components/Home";
import Footer from "./Components/Global/Footer";
import ContactUs from "./Components/ContactUs";
import Error404 from "./Components/Error404";
import MVV from "./Components/MVV";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact-us/" element={<ContactUs />} />
        <Route path="nosotros/" element={<MVV />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
